import "./App.css";
import { useGaTracker } from "./hooks/useGaTracker";
import { useFBPixel } from "./hooks/useFBPixel";

import {
  BrowserRouter as Router,
  //  Routes,
  //  Route,
  useRoutes,
} from "react-router-dom";

import { PlayerCarousel } from "./components/VideoPlayer/PlayerCarousel";

const App = () => {
  useGaTracker();
  useFBPixel();

  let routes = useRoutes([
    {
      path: "/bowl",
      element: (
        <div className="App">
          <PlayerCarousel game={"bowl"} />
        </div>
      ),
    },
    {
      path: "/darts",
      element: (
        <div className="App">
          <PlayerCarousel game={"darts"} />
        </div>
      ),
    },
    {
      path: "/cornhole",
      element: (
        <div className="App">
          <PlayerCarousel game={"cornhole"} />
        </div>
      ),
    },
    {
      path: "/pool",
      element: (
        <div className="App">
          <PlayerCarousel game={"pool"} />
        </div>
      ),
    },
    {
      path: "*",
      element: (
        <div className="App">
          <PlayerCarousel />
        </div>
      ),
    },
  ]);
  return routes;
};
const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
export default AppWrapper;
