import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

export const useFBPixel = () => {

  const FB_PIXEL = "2292163574268929";

  useEffect(() => {

    ReactPixel.init(FB_PIXEL, null, {
      autoConfig: true,
      debug: true,
    });
    ReactPixel.pageView();
    //ReactPixel.track("MediaPlayerPageView")
  }, []);

};
