import css from "./MenuContainer.module.css";
import { Button } from "./Button";
import { isEmpty } from "lodash";

export const MenuContainer = ({ menuItems, cta }) => {
  const hasCta = !isEmpty(cta);
  return (
    <div className={css.menuContainer}>
      {menuItems.slice(0, hasCta ? 6 : 8).map((menuItem, i) => {
        return (
          <div className={css.buttonContainer} key={i}>
            <Button i={i} menuItem={menuItem} />
          </div>
        );
      })}
      {hasCta && (
        <div className={css.ctaButtonContainer}>
          <Button menuItem={cta} />
        </div>
      )}
    </div>
  );
};
