import css from "./Content.module.css";
import { MenuContainer } from "./MenuContainer";

const Content = ({ json, parentalLock }) => {
  return (
    <>
      <div className={css.bounds}>
        <div className={css.header}>
          <div className={css.headerFont}>{json.header}</div>
        </div>
        {parentalLock ? (
          <div className={css.lockMessage}>
            Parental Controls enabled - Media Player content is locked
          </div>
        ) : (
          <MenuContainer menuItems={json.content} cta={json.cta} />
        )}
      </div>
    </>
  );
};

export default Content;
