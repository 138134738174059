import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // TODO: Replace with your own GA tracking ID
    ReactGA.initialize("UA-204161681-1", {});
    // set dimeinsion1 and dimension2 in your property to desired analytics name
    // https://support.google.com/analytics/answer/2709829?hl=en#zippy=%2Cin-this-article
    // TODO: Replace dimension1 and dimension2 with actual names
    // Could be set in with a query param if needed
    // You can see user dimension1 and dimension2 in GA dashboard
    // Audience => User Explorer => (any client id) Custom dimensions
    ReactGA.set({ dimension1: "username", dimension2: "ocusername" });

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};
