import css from "./Button.module.css";
import { OutboundLink } from "react-ga";

export const Button = ({ i, menuItem }) => {
  const {
    middleText,
    url,
    topText = "Sub-title goes here",
    bottomText = "Sub-title goes here",
  } = menuItem;

  return (
    <div className={css.buttonContainer}>
      {/*
      Go to GA page, Behavior => events => pages => / => Outbound => Click
       */}
      <OutboundLink eventLabel={`external#${url}`} to={url}>
        <div className={css.buttonUnderlay} />
        <div className={css.buttonInnerContainer}>
          <div className={css[menuItem.icon]} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div className={css.buttonText}>{topText}</div>
            <div className={css.buttonTitle}>{middleText}</div>
            <div className={css.buttonText}>{bottomText}</div>
          </div>
        </div>
      </OutboundLink>
    </div>
  );
};
