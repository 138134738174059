export const json = {
  none: {
    music: {
      header: "YouTube Music",
      cta: {},
      content: [
        {
          topText: "Never gonna give you up",
          middleText: "80’s Mix",
          bottomText: "Never gonna let you down",
          url:
            "https://www.youtube.com/watch?v=dQw4w9WgXcQ&list=PLCD0445C57F2B7F41&ab_channel=RickAstley",
          icon: "iconVinyl",
        },
        {
          topText: "It’s time to dance",
          middleText: "Pop Hits",
          bottomText: "Strikes for days",
          url:
            "https://www.youtube.com/watch?v=OPf0YbXqDm0&list=PLMC9KNkIncKtPzgY-5rmhvj7fax8fdxoj&index=1&ab_channel=MarkRonsonVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "East coast to west coast",
          middleText: "Hip Hop",
          bottomText: "We love it all",
          url:
            "https://www.youtube.com/watch?v=5qm8PH4xAss&list=PLvuMfxvpAQrkzez9insKS8cGPU74sK1Ss&index=2",
          icon: "iconVinyl",
        },
        {
          topText: "Raise your horns",
          middleText: "Metal",
          bottomText: "It’s time to rock",
          url:
            "https://www.youtube.com/watch?v=xnKhsTXoKCI&list=PLhQCJTkrHOwSX8LUnIMgaTq3chP1tiTut&ab_channel=MotherRussiaMr",
          icon: "iconVinyl",
        },
        {
          topText: "Smells like a perfect game",
          url:
            "https://www.youtube.com/watch?v=0J2QdDbelmY&list=PL2356AAD0BA8452BE&ab_channel=TheWhiteStripes",
          bottomText: "It’s time for angst ",
          middleText: "Alt Rock",
          icon: "iconVinyl",
        },
        {
          topText: "Is this the real life…",
          middleText: "Classic Rock",
          bottomText: "…is this just fantasy",
          url:
            "https://www.youtube.com/watch?v=fJ9rUzIMcZQ&list=PLNxOe-buLm6cz8UQ-hyG1nm3RTNBUBv3K&ab_channel=QueenOfficial",
          icon: "iconVinyl",
        },
        {
          topText: "Playing 9 to 5",
          middleText: "Country",
          bottomText: "We got you covered",
          url:
            "https://www.youtube.com/watch?v=VuNIsY6JdUw&list=PLZFepeq6ZN_CmPrJAR1jfDBLrxi3klEvC&ab_channel=TaylorSwiftVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "Tap to Search",
          middleText: "YouTube",
          bottomText: "Tap to Search",
          url: "https://www.youtube.com",
          icon: "iconVinyl",
        },
      ],
    },
  },
  cornhole: {
    music: {
      header: "YouTube Music",
      cta: {},
      content: [
        {
          topText: "Never gonna give you up",
          middleText: "80’s Mix",
          bottomText: "Never gonna let you down",
          url:
            "https://www.youtube.com/watch?v=dQw4w9WgXcQ&list=PLCD0445C57F2B7F41&ab_channel=RickAstley",
          icon: "iconVinyl",
        },
        {
          topText: "It’s time to dance",
          middleText: "Pop Hits",
          bottomText: "Strikes for days",
          url:
            "https://www.youtube.com/watch?v=OPf0YbXqDm0&list=PLMC9KNkIncKtPzgY-5rmhvj7fax8fdxoj&index=1&ab_channel=MarkRonsonVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "East Coast to West Coast",
          middleText: "Hip Hop",
          bottomText: "We love it all",
          url:
            "https://www.youtube.com/watch?v=5qm8PH4xAss&list=PLvuMfxvpAQrkzez9insKS8cGPU74sK1Ss&index=2",
          icon: "iconVinyl",
        },
        {
          topText: "Raise your horns",
          middleText: "Metal",
          bottomText: "It’s time to rock",
          url:
            "https://www.youtube.com/watch?v=xnKhsTXoKCI&list=PLhQCJTkrHOwSX8LUnIMgaTq3chP1tiTut&ab_channel=MotherRussiaMr",
          icon: "iconVinyl",
        },
        {
          topText: "Smells like a perfect game",
          url:
            "https://www.youtube.com/watch?v=0J2QdDbelmY&list=PL2356AAD0BA8452BE&ab_channel=TheWhiteStripes",
          bottomText: "It’s time for angst",
          middleText: "Alt Rock",
          icon: "iconVinyl",
        },
        {
          topText: "Is this the real life…",
          middleText: "Classic Rock",
          bottomText: "…is this just fantasy",
          url:
            "https://www.youtube.com/watch?v=fJ9rUzIMcZQ&list=PLNxOe-buLm6cz8UQ-hyG1nm3RTNBUBv3K&ab_channel=QueenOfficial",
          icon: "iconVinyl",
        },
        {
          topText: "Playing 9 to 5?",
          middleText: "Country",
          bottomText: "We got you covered",
          url:
            "https://www.youtube.com/watch?v=VuNIsY6JdUw&list=PLZFepeq6ZN_CmPrJAR1jfDBLrxi3klEvC&ab_channel=TaylorSwiftVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "Cool vibes",
          middleText: "Reggae",
          bottomText: "Time to chill and throw",
          url:
            "https://www.youtube.com/watch?v=_tfqK_ar77c&ab_channel=JetStarMusic",
          icon: "iconVinyl",
        },
      ],
    },
    help: {
      header: "Help & How To",
      cta: {
        icon: "iconQuestion",
        url: "https://forevrgames.zendesk.com/hc/en-us/requests/new",
        middleText: "Contact Us Here",
        topText: "Have a question?",
        bottomText: "We are here to help!",
      },
      content: [
        {
          topText: "3 for a Cornhole",
          middleText: "Scoring Points",
          bottomText: "1 for on the board",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/8803991879053",
          icon: "iconQuestion",
        },
        {
          topText: "Throwing too far??",
          middleText: "Throw Power",
          bottomText: "Check this out!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/8804553933965",
          icon: "iconQuestion",
        },
        {
          topText: "New game mode",
          middleText: "Hall Tour",
          bottomText: "Fun new challenge",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10686486385037",
          icon: "iconQuestion",
        },
        {
          topText: "So many choices!",
          middleText: "Starting a Game",
          bottomText: "We are here to help!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/8781238574477",
          icon: "iconQuestion",
        },
        {
          topText: "What is an airmail?",
          middleText: "Glossary",
          bottomText: "All cornhole terms!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/8806484483597",
          icon: "iconQuestion",
        },
        {
          topText: "New to ForeVR Cornhole?",
          middleText: "Tutorial",
          bottomText: "Check this out!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/8832766444429",
          icon: "iconQuestion",
        }
      ],
    },
    creator: {
      header: "Featured Creators",
      cta: {
        icon: "iconQuestion",
        url: "https://forevrgames.zendesk.com/hc/en-us/requests/new",
        middleText: "Contact Us Here",
        topText: "Have a question?",
        bottomText: "We are here to help!",
      },
      content: [
        {
          topText: "Multiplayer is fun!",
          middleText: "BMFVR",
          bottomText: "Home for all things quest!",
          url: "https://www.youtube.com/watch?v=XDwobf-uyWk&ab_channel=BMFVR",
          icon: "iconStar",
        },
        {
          topText: "Pushed me to my limits!",
          middleText: "David Duggan",
          bottomText: "Can’t miss my shot!",
          url:
            "https://www.youtube.com/watch?v=FNK_sgSfTTQ&ab_channel=DavidDuggan",
          icon: "iconStar",
        },
        {
          topText: "Become The Great Cornholio",
          middleText: "Fun With Clicks",
          bottomText: "VR gaming channel",
          url:
            "https://www.youtube.com/watch?v=0yhtupfAfCs&ab_channel=FunWithTheClicks",
          icon: "iconStar",
        },
        {
          topText: "UK vs US In The Backyard",
          middleText: "Resol VR",
          bottomText: "I play VR games!",
          url:
            "https://www.youtube.com/watch?v=ix0J3AG53i4&ab_channel=ResolVR",
          icon: "iconStar",
        },
        {
          topText: "First impressions!",
          middleText: "GameOnScorpio",
          bottomText: "Gamer and tech lover!",
          url:
            "https://www.youtube.com/watch?v=kXOm6oy9Mdc&ab_channel=GameOnScorpio",
          icon: "iconStar",
        },
        {
          topText: "I am the Great Cornholio",
          middleText: "WelshGamerVR",
          bottomText: "Welsh pride and happiness!",
          url:
            "https://www.youtube.com/watch?v=9hP9MBfiyNk&ab_channel=WelshGamerVR",
          icon: "iconStar",
        },
      ],
    },
  },
  pool: {
    music: {
      header: "YouTube Music",
      cta: {},
      content: [
        {
          topText: "Never gonna give you up",
          middleText: "80’s Mix",
          bottomText: "Never gonna let you down",
          url:
            "https://www.youtube.com/watch?v=dQw4w9WgXcQ&list=PLCD0445C57F2B7F41&ab_channel=RickAstley",
          icon: "iconVinyl",
        },
        {
          topText: "It’s time to dance",
          middleText: "Pop Hits",
          bottomText: "Strikes for days",
          url:
            "https://www.youtube.com/watch?v=OPf0YbXqDm0&list=PLMC9KNkIncKtPzgY-5rmhvj7fax8fdxoj&index=1&ab_channel=MarkRonsonVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "East coast to west coast",
          middleText: "Hip Hop",
          bottomText: "We love it all",
          url:
            "https://www.youtube.com/watch?v=5qm8PH4xAss&list=PLvuMfxvpAQrkzez9insKS8cGPU74sK1Ss&index=2",
          icon: "iconVinyl",
        },
        {
          topText: "Raise your horns",
          middleText: "Metal",
          bottomText: "It’s time to rock",
          url:
            "https://www.youtube.com/watch?v=xnKhsTXoKCI&list=PLhQCJTkrHOwSX8LUnIMgaTq3chP1tiTut&ab_channel=MotherRussiaMr",
          icon: "iconVinyl",
        },
        {
          topText: "Smells like a perfect game",
          url:
            "https://www.youtube.com/watch?v=0J2QdDbelmY&list=PL2356AAD0BA8452BE&ab_channel=TheWhiteStripes",
          bottomText: "It’s time for angst ",
          middleText: "Alt Rock",
          icon: "iconVinyl",
        },
        {
          topText: "Is this the real life…",
          middleText: "Classic Rock",
          bottomText: "…is this just fantasy",
          url:
            "https://www.youtube.com/watch?v=fJ9rUzIMcZQ&list=PLNxOe-buLm6cz8UQ-hyG1nm3RTNBUBv3K&ab_channel=QueenOfficial",
          icon: "iconVinyl",
        },
        {
          topText: "Playing 9 to 5?",
          middleText: "Country",
          bottomText: "We got you covered",
          url:
            "https://www.youtube.com/watch?v=VuNIsY6JdUw&list=PLZFepeq6ZN_CmPrJAR1jfDBLrxi3klEvC&ab_channel=TaylorSwiftVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "Cool vibes",
          middleText: "Reggae",
          bottomText: "Time to chill",
          url:
            "https://www.youtube.com/watch?v=_tfqK_ar77c&ab_channel=JetStarMusic",
          icon: "iconVinyl",
        },
        {
          topText: "Tap to Search",
          middleText: "YouTube",
          bottomText: "Tap to Search",
          url: "https://www.youtube.com",
          icon: "iconVinyl",
        },
      ],
    },
    help: {
      header: "Help & How To",
      cta: {
        icon: "iconQuestion",
        url: "https://forevrgames.zendesk.com/hc/en-us/requests/new",
        topText: "Have a question?",
        middleText: "Contact Us Here",
        bottomText: "We're Here to Help!",
      },
      content: [
        {
          topText: "All things pool",
          middleText: "Pool Rules",
          bottomText: "Be pro in no time",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10660295504653",
          icon: "iconQuestion",
        },
        {
          topText: "Time to pocket",
          middleText: "Aim & Lock",
          bottomText: "Check this out!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10660372206861",
          icon: "iconQuestion",
        },
        {
          topText: "Need to line up quick?",
          middleText: "Recenter",
          bottomText: "Getting to the throw space",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10661067605517",
          icon: "iconQuestion",
        },
        {
          topText: "So many choices!",
          middleText: "Starting a Game",
          bottomText: "We are here to help!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10661387074445",
          icon: "iconQuestion",
        },
        {
          topText: "What is a foul?",
          middleText: "Glossary",
          bottomText: "All pool terms!",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10660866536845",
          icon: "iconQuestion",
        },
        {
          topText: "Power & Spin",
          middleText: "Cues Stats",
          bottomText: "All you need to know",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10660452884109",
          icon: "iconQuestion",
        },
      ],
    },
    creator: {
      header: "Featured Creators",
      cta: {
        icon: "iconQuestion",
        url: "https://forevrgames.zendesk.com/hc/en-us/requests/new",
        topText: "Have a question?",
        middleText: "Contact Us Here",
        bottomText: "We are here to help!",
      },
      content: [
        {
          topText: "The unofficial home for...",
          middleText: "BMFVR",
          bottomText: "Home for all things quest!",
          url: "https://www.youtube.com/c/BMFVR",
          icon: "iconStar",
        },
        {
          topText: "Game reviews",
          middleText: "Lipnox",
          bottomText: "How tos and more!",
          url:
            "https://www.youtube.com/@LipnoxVR",
          icon: "iconStar",
        },
        {
          topText: "Motivation to...",
          middleText: "Jdunrrp",
          bottomText: "Getting Things Dun",
          url:
            "https://www.youtube.com/@GettingThingsDun/about",
          icon: "iconStar",
        },
        {
          topText: "Virtual Reality Gaming",
          middleText: "Resol VR",
          bottomText: "I play VR games!",
          url:
            "https://www.youtube.com/c/ResolVRGaming",
          icon: "iconStar",
        },
        {
          topText: "VR Content Creator",
          middleText: "GameOnScorpio",
          bottomText: "Gamer and tech lover!",
          url:
            "https://www.youtube.com/c/GameOnScorpio/featured",
          icon: "iconStar",
        },
        {
          topText: "VR Gaming Channel",
          middleText: "ClicksterVR",
          bottomText: "Journey into VR",
          url:
            "https://www.youtube.com/@ClicksterVR",
          icon: "iconStar",
        },
      ],
    },
  },
  bowl: {
    music: {
      header: "YouTube Music",
      cta: {},
      content: [
        {
          topText: "Never gonna give you up",
          middleText: "80’s Mix",
          bottomText: "Never gonna let you down",
          url:
            "https://www.youtube.com/watch?v=dQw4w9WgXcQ&list=PLCD0445C57F2B7F41&ab_channel=RickAstley",
          icon: "iconVinyl",
        },
        {
          topText: "It’s time to dance",
          middleText: "Pop Hits",
          bottomText: "Strikes for days",
          url:
            "https://www.youtube.com/watch?v=OPf0YbXqDm0&list=PLMC9KNkIncKtPzgY-5rmhvj7fax8fdxoj&index=1&ab_channel=MarkRonsonVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "East coast to west coast",
          middleText: "Hip Hop",
          bottomText: "We love it all",
          url:
            "https://www.youtube.com/watch?v=5qm8PH4xAss&list=PLvuMfxvpAQrkzez9insKS8cGPU74sK1Ss&index=2",
          icon: "iconVinyl",
        },
        {
          topText: "Raise your horns",
          middleText: "Metal",
          bottomText: "It’s time to rock",
          url:
            "https://www.youtube.com/watch?v=xnKhsTXoKCI&list=PLhQCJTkrHOwSX8LUnIMgaTq3chP1tiTut&ab_channel=MotherRussiaMr",
          icon: "iconVinyl",
        },
        {
          topText: "Smells like a perfect game",
          url:
            "https://www.youtube.com/watch?v=0J2QdDbelmY&list=PL2356AAD0BA8452BE&ab_channel=TheWhiteStripes",
          bottomText: "It’s time for angst ",
          middleText: "Alt Rock",
          icon: "iconVinyl",
        },
        {
          topText: "Is this the real life…",
          middleText: "Classic Rock",
          bottomText: "…is this just fantasy",
          url:
            "https://www.youtube.com/watch?v=fJ9rUzIMcZQ&list=PLNxOe-buLm6cz8UQ-hyG1nm3RTNBUBv3K&ab_channel=QueenOfficial",
          icon: "iconVinyl",
        },
        {
          topText: "Bowling 9 to 5?",
          middleText: "Country",
          bottomText: "We got you covered",
          url:
            "https://www.youtube.com/watch?v=VuNIsY6JdUw&list=PLZFepeq6ZN_CmPrJAR1jfDBLrxi3klEvC&ab_channel=TaylorSwiftVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "Tap to Search",
          middleText: "YouTube",
          bottomText: "Tap to Search",
          url: "https://www.youtube.com",
          icon: "iconVinyl",
        },
      ],
    },
    help: {
      header: "Help & How To",
      cta: {
        icon: "iconQuestion",
        url: "https://www.forevrgames.com/support",
        middleText: "Contact Us Here",
        topText: "Still Heed help?",
        bottomText: "We're Here to Help",
      },
      content: [
        {
          topText: "So many halls to choose from",
          middleText: "Halls",
          bottomText: "Play in a different hall",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417820500621-How-do-I-play-in-a-different-hall-",
          icon: "iconQuestion",
        },
        {
          topText: "Hello?",
          middleText: "Mic Issues",
          bottomText: "Can you hear me now?",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417081479565-Issues-with-audio-Other-Players-can-t-hear-me-",
          icon: "iconQuestion",
        },
        {
          topText: "So many balls to find!",
          middleText: "Hidden Balls",
          bottomText: "Time to explore",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4416012831885-What-are-the-Hidden-balls-and-how-do-I-find-them-",
          icon: "iconQuestion",
        },
        {
          topText: "Ready to play with friends? ",
          middleText: "Multiplayer",
          bottomText: "Here is how to do it",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4416025448077-How-do-I-start-a-multiplayer-game-",
          icon: "iconQuestion",
        },
        {
          topText: "Finding your throw",
          middleText: "Throw types",
          bottomText: "Default or Classic",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4416032446221-Default-vs-Classic-Throw",
          icon: "iconQuestion",
        },
        {
          topText: "What balls you should pick",
          middleText: "Gutterballs",
          bottomText: "If you're guttering too much",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417762348813-What-balls-should-I-pick-if-I-am-guttering-too-much-",
          icon: "iconQuestion",
        },
        {
          topText: "Choosing a great ball",
          middleText: "Beginner Balls",
          bottomText: "for beginners",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417776873357-Best-Balls-for-Beginners",
          icon: "iconQuestion",
        },
      ],
    },
    creator: {
      header: "Featured Creators",
      cta: {
        icon: "iconQuestion",
        url: "https://www.forevrgames.com/support",
        middleText: "Contact Us Here",
        topText: "Still Heed help?",
        bottomText: "We're Here to Help",
      },
      content: [
        {
          topText: "Wii Bowling VR Is HERE",
          middleText: "BMFVR",
          bottomText: "Home for all things Quest",
          url: "https://www.youtube.com/watch?v=q7iVKWMl55o&ab_channel=BMFVR",
          icon: "iconStar",
        },
        {
          topText: "ForeVR Bowl Review",
          middleText: "Shugghead Gaming",
          bottomText: "Escape into reality ",
          url:
            "https://www.youtube.com/watch?v=66DEyv6gSbg&ab_channel=ShuggheadGaming",
          icon: "iconStar",
        },
        {
          topText: "Bowling in Virtual Reality ",
          middleText: "OtterWorldly",
          bottomText: "Mixed reality + Let’s Plays",
          url:
            "https://www.youtube.com/watch?v=PJCu2gLEz7E&t=77s&ab_channel=OtterWorldly",
          icon: "iconStar",
        },
        {
          topText: "ForeVR Bowl Review",
          middleText: "Lipnox VR",
          bottomText: "Oculus Quest Game Reviews ",
          url:
            "https://www.youtube.com/watch?v=POS9hQk3hrw&ab_channel=LipnoxVR",
          icon: "iconStar",
        },
        {
          topText: "ForeVR Bowling Gameplay",
          middleText: "AGirlAndAGame",
          bottomText: "Let’s plays gaming and more! ",
          url:
            "https://www.youtube.com/watch?v=IEi09XCUFDY&ab_channel=AGirlAndAGame",
          icon: "iconStar",
        },
        {
          topText: "Bowling Multiplayer Gameplay",
          middleText: "Gamertag VR",
          bottomText: "Virtual Reality Gaming",
          url:
            "https://www.youtube.com/watch?v=oP3rhjUeyS4&ab_channel=GamertagVR",
          icon: "iconStar",
        },
      ],
    },
  },
  darts: {
    music: {
      header: "YouTube Music",
      cta: {},
      content: [
        {
          topText: "Never gonna give you up",
          middleText: "80’s Mix",
          bottomText: "Never gonna let you down",
          url:
            "https://www.youtube.com/watch?v=dQw4w9WgXcQ&list=PLCD0445C57F2B7F41&ab_channel=RickAstley",
          icon: "iconVinyl",
        },
        {
          topText: "It’s time to dance",
          middleText: "Pop Hits",
          bottomText: "Bullseyes for days",
          url:
            "https://www.youtube.com/watch?v=OPf0YbXqDm0&list=PLMC9KNkIncKtPzgY-5rmhvj7fax8fdxoj&index=1&ab_channel=MarkRonsonVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "East coast to west coast",
          middleText: "Hip Hop",
          bottomText: "We love it all",
          url:
            "https://www.youtube.com/watch?v=5qm8PH4xAss&list=PLvuMfxvpAQrkzez9insKS8cGPU74sK1Ss&index=2",
          icon: "iconVinyl",
        },
        {
          topText: "Raise your horns",
          middleText: "Metal",
          bottomText: "It’s time to rock",
          url:
            "https://www.youtube.com/watch?v=xnKhsTXoKCI&list=PLhQCJTkrHOwSX8LUnIMgaTq3chP1tiTut&ab_channel=MotherRussiaMr",
          icon: "iconVinyl",
        },
        {
          topText: "Smells like a perfect game",
          middleText: "Alt Rock",
          bottomText: "It’s time for angst ",
          url:
            "https://www.youtube.com/watch?v=0J2QdDbelmY&list=PL2356AAD0BA8452BE&ab_channel=TheWhiteStripes",
          icon: "iconVinyl",
        },
        {
          topText: "Is this the real life…",
          middleText: "Classic Rock",
          bottomText: "…is this just fantasy",
          url:
            "https://www.youtube.com/watch?v=fJ9rUzIMcZQ&list=PLNxOe-buLm6cz8UQ-hyG1nm3RTNBUBv3K&ab_channel=QueenOfficial",
          icon: "iconVinyl",
        },
        {
          topText: "Throwing Darts 9 to 5?",
          middleText: "Country",
          bottomText: "We got you covered",
          url:
            "https://www.youtube.com/watch?v=VuNIsY6JdUw&list=PLZFepeq6ZN_CmPrJAR1jfDBLrxi3klEvC&ab_channel=TaylorSwiftVEVO",
          icon: "iconVinyl",
        },
        {
          topText: "Tap to Search",
          middleText: "YouTube",
          bottomText: "Tap to Search",
          url: "https://www.youtube.com",
          icon: "iconVinyl",
        },
      ],
    },
    help: {
      header: "Help & How To",
      cta: {
        icon: "iconQuestion",
        url: "https://www.forevrgames.com/support",
        middleText: "Contact Us Here",
        topText: "Still Heed help?",
        bottomText: "We're Here to Help",
      },
      content: [
        {
          topText: "We will show you how",
          middleText: "Game modes",
          bottomText: "301, Single out, and more",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417067361805-How-do-I-change-my-game-mode-ex-201-to-301-",
          icon: "iconQuestion",
        },
        {
          topText: "Seeing new heights ",
          middleText: "Height",
          bottomText: "Adjust your height in game",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4417168012941-How-do-I-adjust-my-height-in-game-",
          icon: "iconQuestion",
        },
        {
          topText: "Ready to play with friends",
          middleText: "Multiplayer",
          bottomText: "Here is how to do it ",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4416121955853-How-do-I-start-a-multiplayer-game-",
          icon: "iconQuestion",
        },
        {
          topText: "New Game Mode!",
          middleText: "Darts Royale",
          bottomText: "Hit the targets!",
          url: "https://forevrgames.zendesk.com/hc/en-us/articles/13297989896205-Darts-Royale",
          icon: "iconQuestion",
        },
        {
          topText: "Share music with your party",
          middleText: "Media Share",
          bottomText: "How to start sharing",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/10333079627405",
          icon: "iconQuestion",
        },
        /*
        {
          topText: "Free from controllers ",
          middleText: "Hand Tracking",
          bottomText: "It is easy as…",
          url:
            "https://forevrgames.zendesk.com/hc/en-us/articles/4416828602509-How-do-I-turn-on-Hand-Tracking-on-my-Oculus-device-",
          icon: "iconQuestion",
        },
        */
        {
          topText: "How to play",
          middleText: "Cricket",
          bottomText: "How to win",
          url: "https://forevrgames.zendesk.com/hc/en-us/articles/5350709289869-Cricket-How-to-play-",
          icon: "iconQuestion",
        },
      ],
    },
    creator: {
      header: "Featured Creators",
      cta: {
        icon: "iconQuestion",
        url: "https://www.forevrgames.com/support",
        middleText: "Contact Us Here",
        topText: "Still Heed help?",
        bottomText: "We're Here to Help",
      },
      content: [
        {
          topText: "Darts Is Great With Friends! ",
          middleText: "BMFVR",
          bottomText: "Home for all things Quest",
          url:
            "https://www.youtube.com/watch?v=4l2qnu78xZo&t=110s&ab_channel=BMFVR",
          icon: "iconStar",
        },
        {
          topText: "Bring the pub to you!",
          middleText: "THE VR GRID",
          bottomText: "Gaming beyond reality ",
          url:
            "https://www.youtube.com/watch?v=2TzGTNEKKe4&ab_channel=THEVRGRID",
          icon: "iconStar",
        },
        {
          topText: "Darts with Hand Tracking",
          middleText: "OtterWorldly",
          bottomText: "Mixed reality + Let’s Plays ",
          url:
            "https://www.youtube.com/watch?v=76clUpFx_6g&ab_channel=OtterWorldly",
          icon: "iconStar",
        },
        {
          topText: "Best Hand Tracking Game?",
          middleText: "Lipnox VR",
          bottomText: "Oculus Quest Game Reviews ",
          url:
            "https://www.youtube.com/watch?v=-hqaHIJT9TE&ab_channel=LipnoxVR",
          icon: "iconStar",
        },
        {
          topText: "ForeVR Darts Review",
          middleText: "SpookyFairy",
          bottomText: "Guides, Gameplay, New Games",
          url:
            "https://www.youtube.com/watch?v=J5Lq1yTYU88&ab_channel=SpookyFairy",
          icon: "iconStar",
        },
        {
          topText: "I Am The BEST Darts Player? ",
          middleText: "AGirlAndAGame",
          bottomText: "Let’s play, gaming and more!",
          url:
            "https://www.youtube.com/watch?v=3jCwf6_0YII&ab_channel=AGirlAndAGame",
          icon: "iconStar",
        },
      ],
    },
  },
};
