// Potential fix for slider problem https://justacoding.blog/build-a-react-carousel-component-with-infinite-scroll/

import { useEffect, useState } from "react";
import { noop } from "lodash";
import css from "./PlayerCarousel.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Content from "./Content";
import { json } from "../../fixture-data";

// Work-around for the fact react-responsive-carousel doesn't support left to right scroll on the last card.
// When a user scrolls to the end of the carousel or beginning, we will set the transition time to zero and
// silently move them to the card in the middle of the stack.
// Controls are locked during the transition to prevent buggy behavior during the transition.
const TRANSITION_TIME = 500;

export function PlayerCarousel({ game }) {
  if (!game) {
    game = "none";
  }

  const urlParams = new URLSearchParams(window.location.search);
  const parentalLock = urlParams.get("parental-lock");

  const [autoPlay, setAutoPlay] = useState(true);
  const [interval, setInterval] = useState(5000);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [transitionTime, setTransitionTime] = useState(TRANSITION_TIME);
  const [buttonLock, setButtonLock] = useState(false);

  useEffect(() => {
    window.addEventListener("focus", () => {
      setAutoPlay(false);
      setInterval(null);
    });
    window.addEventListener("blur", () => {
      setAutoPlay(true);
      setInterval(5000);
    });
  }, []);

  const rightArrow = (clickHandler, hasNext, labelNext) => {
    return (
      <button
        onClick={buttonLock ? noop : clickHandler}
        className={css.rightArrow}
      ></button>
    );
  };
  const leftArrow = (clickHandler, hasNext, labelNext) => {
    return (
      <button
        onClick={buttonLock ? noop : clickHandler}
        className={css.leftArrow}
      ></button>
    );
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
      if (index === 4) {
        setTimeout(() => {
          setTransitionTime(0);
          setCurrentSlide(1);
          setTransitionTime(TRANSITION_TIME);
        }, TRANSITION_TIME);
      }
      if (index === 0) {
        setTimeout(() => {
          setTransitionTime(0);
          setCurrentSlide(3);
          setTransitionTime(TRANSITION_TIME);
        }, TRANSITION_TIME);
      }
      setButtonLock(true);
      setTimeout(() => {
        setButtonLock(false);
      }, TRANSITION_TIME);
    }
  };

  const slides = ["creator", "help", "music", "creator", "help"];

  const slideDeck = slides
    .map((i) => {
      if (json[game][i]) {
        return (
          <Content
            json={json[game][i]}
            parentalLock={Boolean(Number(parentalLock))}
          />
        );
      }
      return null;
    })
    .filter((x) => x);

  if (slideDeck.length === 1) {
    return slideDeck[0];
  }

  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      infiniteLoop={true}
      renderIndicator={false}
      centerMode={true}
      showStatus={false}
      renderArrowNext={rightArrow}
      renderArrowPrev={leftArrow}
      selectItem={0}
      animationHandler={null}
      selectedItem={currentSlide}
      onChange={updateCurrentSlide}
      transitionTime={transitionTime}
      autoPlay={autoPlay}
      interval={interval}
    >
      <Content
        json={json[game]["creator"]}
        parentalLock={Boolean(Number(parentalLock))}
      />
      <Content
        json={json[game]["help"]}
        parentalLock={Boolean(Number(parentalLock))}
      />
      <Content
        json={json[game]["music"]}
        parentalLock={Boolean(Number(parentalLock))}
      />
      <Content
        json={json[game]["creator"]}
        parentalLock={Boolean(Number(parentalLock))}
      />
      <Content
        json={json[game]["help"]}
        parentalLock={Boolean(Number(parentalLock))}
      />
    </Carousel>
  );
}
